import ReactGA from 'react-ga'

export const ANALYTICS_TOKEN: string = process.env.NODE_ENV === 'production' ? 'UA-148493780-3' : 'UA-196492165-1' // non-production token is generic test account

// ------------------
// initialise Google Analytics
// ------------------
export const initGA = (id: string) => {
  if (!id) return
  ReactGA.initialize(id,
    {
      titleCase: false
    }
  )
}

// ------------------
// log an event
// ------------------
export const logEvent = ({
  category = '',
  action = '',
  label = '',
  value = undefined
}) => {
  if (category && action) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value,
      nonInteraction: true
    })
  }
}

// ------------------
// set a custom dimension
// ------------------
export const setDimension = ({
  dimension = '',
  value = ''
}) => {
  if (!dimension || !value) return
  ReactGA.set({ [dimension]: value });
}

// ------------------
// set the custom "userId" dimension
// ------------------
export const setGAUser = ({
  username = ''
}) => {
  ReactGA.set({ userId: username });
}

// ------------------
// log a page view
// ------------------
export const logPageView = (title?: string) => {
  ReactGA.set({ page: window?.location?.pathname })
  ReactGA.pageview(window?.location?.pathname, undefined, title)
}
