import { NextPageContext } from 'next'
import { removeCookie } from '../services/cookies'
import http from '../services/http'
import { getCookie } from '../services/cookies'

export const addAuthorizationHeader = (token: string) => {
  if (token) {
    http.defaults.headers.common['Authorization'] = ''
    delete http.defaults.headers.common['Authorization']
    http.defaults.headers.common['Authorization'] = `Token ${token}`
  }
}

export const removeAuthorizationHeader = (ctx?: NextPageContext) => {
  const token = getCookie('token', ctx)
  if (!!token && ctx?.pathname != '/login') {
    removeCookie('token', ctx)
    removeCookie('csrftoken', ctx)
    removeCookie('sessionid', ctx)
    http.defaults.headers.common['Authorization'] = ''
    delete http.defaults.headers.common['Authorization']
  }
}
