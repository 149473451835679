const BASEURL =
  process.env.NEXT_PUBLIC_API_ENDPOINT ||
  'https://api-footprint.techequipt.com.au' // Staging
const AUTH_BASE = `${BASEURL}/rest-auth`
const API_BASE = `${BASEURL}/api`

export const AUTH_URL = {
  login: `${AUTH_BASE}/login/`,
  logout: `${AUTH_BASE}/logout/`,
  resetPassword: `${AUTH_BASE}/password/reset/`,
  updatePassword: `${AUTH_BASE}/password/change/`,
}

export const API_URL = {
  users: `${API_BASE}/users/`,
  validate_token: `${API_BASE}/user-invite/validate-token/`,
  user_invite: `${API_BASE}/user-invite/`,
  categories: `${API_BASE}/categories/`,
  landingPage: `${API_BASE}/landing_page/`,
  news: `${API_BASE}/news/`,
  autocomplete: `${API_BASE}/autocomplete/`,
  materials: `${API_BASE}/materials/`,
  subElements: `${API_BASE}/sub-elements/`,
  disclosurePage: `${API_BASE}/disclosure_page/`,
  userRatings: `${API_BASE}/user-ratings/`,
  compareCarbonData: `${API_BASE}/compare-carbon-data/`,
  helpPage: `${API_BASE}/help_page/`,
  calculators: `${API_BASE}/calculators/`,
  calculatorsProjects: `${API_BASE}/calculators/projects/`,
  buildingTypes: `${API_BASE}/calculators/building-types/`,
  calculatorsModules: `${API_BASE}/calculators/modules/`,
  calculatorsAssessments: `${API_BASE}/calculators/assessments/`,
  calculatorsQuestions: `${API_BASE}/calculators/questions/`,
  transportGroups: `${API_BASE}/calculators/transport-groups/`,
  locations: `${API_BASE}/locations/`,
  countries: `${API_BASE}/countries/`,
  questionScore: `${API_BASE}/assessments/question-score/`,
  reportingConstants: `${API_BASE}/calculators/reporting-constants/`,
  precinctReportingConstants: `${API_BASE}/calculators/precinct-reporting-constants/`,
  calculatorsDesignStages: `${API_BASE}/calculators/assessments/design-stages/`,
  termsPage: `${API_BASE}/terms_page/`,
  ecoReportingConstants: `${API_BASE}/calculators/eco-reporting-constants/`,
  precinctEcoReportingConstants: `${API_BASE}/calculators/precinct-eco-reporting-constants/`,
  companyUsers: `${API_BASE}/company-users/`,
  greenBookHelpPage: `${API_BASE}/greenbook-help-page/`,
}
