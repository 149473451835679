import { createContext } from 'react'
import { User } from '../services/types'

type UserContextType = {
  user: User | null
  setUser: (value: User | null) => void
}

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
})
