import { createContext } from 'react'
import { MenuItem } from '../services/types'

type MenuContextType = {
  menu: MenuItem[] | null
  setMenu: (value: MenuItem[] | null) => void
}

export const MenuContext = createContext<MenuContextType>({
  menu: null,
  setMenu: () => {},
})
