import * as cookies from 'cookies-next'
import { NextPageContext } from 'next'

export const setCookie = (key: string, value: string, expires: any) => {
  cookies.setCookie(key, value, { expires })
}

export const removeCookie = (key: string, ctx?: NextPageContext) => {
  cookies.deleteCookie(key, ctx)
}

export const getCookie = (key: string, ctx?: NextPageContext) => {
  return cookies.getCookie(key, ctx)
}

export const removeCsrftokenSessionIdCookie = (ctx?: NextPageContext) => {
  if (ctx && ctx.res) {
    ctx.res.setHeader('Set-Cookie', [
      `csrftoken=deleted; Max-Age=0`,
      `sessionid=deleted; Max-Age=0`,
    ])
  }
}
