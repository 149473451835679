import React, { ReactNode } from 'react'
import { User } from '../services/types'
import differenceInSeconds from 'date-fns/differenceInSeconds'

export interface ConditionalWrapperProps {
  condition: any
  wrapper: Function
  children: ReactNode
}
export const ConditionalWrapper: Function = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children)

export const stripHtmlToText: Function = (html: string) => {
  let elm = document.createElement('DIV')
  elm.innerHTML = html
  let data = elm.textContent || elm.innerText || ''
  data.replace('\u200B', '')
  data = data.trim()
  return data
}

export const validSubscriber = (user: User, productType?: string) => {
  if (productType) {
    return user?.products?.find((it) => {
      return (
        it.end &&
        differenceInSeconds(new Date(it.end), new Date()) > 0 &&
        it.product === productType
      )
    })
  }

  return user?.products?.find((it) => {
    return it.end && differenceInSeconds(new Date(it.end), new Date()) > 0
  })
}

export const numberFormat = (
  val?: number | string,
  maximumFractionDigits?: number,
  rounded = true
) => {
  if (!val) return ''

  const decimalPlace =
    maximumFractionDigits !== undefined ? maximumFractionDigits : 2

  if (!rounded) {
    let nVal = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)
    return Number(nVal && nVal[0]).toLocaleString('en-US', {
      maximumFractionDigits: decimalPlace,
    })
  }

  return parseFloat(val.toString()).toLocaleString('en-US', {
    maximumFractionDigits: decimalPlace,
  })
}

export const escapedNewLineToLineBreakTag = (string?: string) => {
  if (!string) return '-'
  return string.split('\n').map((item, idx) => {
    return (
      <React.Fragment key={`line-${idx}`}>
        {item}
        <br />
      </React.Fragment>
    )
  })
}


export const stringDividerForChart = (
  str: string = '',
  width: number = 20,
  spaceReplacer: string = '<br>'
): string => {
  if (str.length > width) {
    let p = width
    for (; p > 0 && str[p] != ' '; p--) {}
    if (p > 0) {
      const left = str.substring(0, p)
      const right = str.substring(p + 1)
      return left + spaceReplacer + stringDividerForChart(right, width, spaceReplacer)
    }
  }
  return str
}

export const convertToneToKg = (toneValue: number | undefined, isKgCo2Unit: boolean = false, roundNumber: number = 2) => {
  if (!toneValue) {
    return 0
  }
  if (!isKgCo2Unit) {
    return toneValue
  }
  if (roundNumber > 3) {
    roundNumber = 3
  }
  if (roundNumber < 0) {
    roundNumber = 0
  }
  return Math.round(toneValue * Math.pow(10, roundNumber)) * (1000 / Math.pow(10, roundNumber))
}